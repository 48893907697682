import React from "react"
import "../css/pages/academicAdvantage2023.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/SeamlessEmbed"

const AcademicAdvantageFresno = () => {
  return (
    <Layout language="en">
      <SEO
        title="Academic Advantage at Fresno Oral Maxillofacial Surgery & Dental Implant Center"
        description="Learn more about the 2023 Academic Advantage program at Academic Advantage at Fresno Oral Maxillofacial Surgery & Dental Implant Center"
        lang="en"
      />
      <div className="edu-23">
        <div className="edu-23__section">
          <div className="edu-23__container">
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-desktop-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--desktop"
            />
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-mobile-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--mobile"
            />
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="edu-23__even-grid">
              <div>
                <h1>Let's Talk About Your Future</h1>
                <p>Let's Talk About Your Future</p>
                <ul>
                  <li>
                    When and how to start planning for your healthcare career
                  </li>
                  <li>Tips on preparing for college and medical school</li>
                  <li>
                    The daily routine of a medical and dental professional
                  </li>
                  <li>What you can expect from a medical career</li>
                  <li>What it’s like to be an entrepreneur and a doctor</li>
                  <li>The many healthcare career paths you can take</li>
                </ul>
                <p>
                  In addition to learning from talented and respected medical
                  professionals, representatives from local colleges and
                  nonprofits will be available to provide more information about
                  college admissions, specific topics, and answer questions.
                </p>
              </div>
              <div className="edu-23__date-time">
                <h2>DATE, TIME, & LOCATION:</h2>

                <p>
                  <span className="edu-23__blue bold">TUESDAY</span>
                  <br />
                  September 19, 2023
                  <br />
                  9:30 AM-12:30 PM
                </p>
                <p>
                  <span className="edu-23__blue bold">
                    Satellite Student Union
                  </span>
                  <br />
                  California State University, Fresno
                </p>
                <p>
                  <span className="edu-23__blue bold">Prizes</span>
                  <br />
                  $500 scholarship <br />3 gift cards valued at $100 each
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="edu-23__speakers">
              <h2>FEATURED SPEAKERS:</h2>
              <div className="edu-23__even-grid">
                <div>
                  <p>
                    <span className="bold">Katherine Bass, Pharmacist</span>
                    <br />
                    Pharmacist and Owner of <br />
                    San Joaquin Drug Incorporated
                  </p>
                  <p>
                    <span className="bold">Dr. Hagop Afarian, MD</span>
                    <br />
                    Emergency Medicine at
                    <br />
                    Community Regional Medical Center
                  </p>
                  <p>
                    <span className="bold">Dareen Malaythong, RN</span>
                    <br />
                    Fresno Heart & Surgical Hospital
                  </p>
                </div>
                <div>
                  <p>
                    <span className="bold">Dr. Ardavan Kheradpir, DMD, MD</span>
                    <br />
                    Fresno Oral Maxillofacial Surgery & <br />
                    Dental Implant Center
                  </p>
                  <p>
                    <span className="bold">Dr. Shannon Barnhart, DDS</span>
                    <br />
                    Fresno Oral Maxillofacial Surgery & <br />
                    Dental Implant Center
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AcademicAdvantageFresno
